import { Form } from "react-bootstrap";
import { ObjectUtils } from "../../utils";
import { AlertComponent } from "../Alert";
import Select from "react-select";
import { Fragment } from "react";

export const DropdownComponent = (props: any) => {
  const { inputdata } = props;
  const onChange = (event: any, index: number) => {
    if (inputdata && inputdata[index].handlechange) {
      event.preventDefault();
      const { name, value }: any = event.target;
      return inputdata[index].handlechange({ [name]: value });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onKeyDown = (event: any) => {
    if (inputdata?.handlekeydown && (event.key === "Enter" || event.keyCode === 13)) {
      event.preventDefault();
      inputdata.handlekeydown(event);
    }
  };

  return inputdata?.map((item: any, index: number) => {
    return (
      <Fragment key={`${ObjectUtils.getControlId(item.texttoread)}${index}`}>
        {!item?.skip && (
          <Form.Group className={item.fgclass}>
            {item?.display && (
              <Form.Label
                htmlFor={item?.name}
                className={item?.lblclassname}
                dangerouslySetInnerHTML={{
                  __html: item.display + `${item?.required ? `<span class="ml-mandatory-sign">*</span>&nbsp;` : `&nbsp;`}`
                }}
              />
            )}
            {item?.mode == "view" && (
              <Form.Label htmlFor={item?.name} className={item?.lblclassname}>
                {item?.value}
              </Form.Label>
            )}

            {item?.mode == "edit" &&
              (item?.isSearchSelect ? (
                <Select
                  required={item?.required}
                  tabSelectsValue={item?.tabSelectsValue ?? true}
                  className="mb-4"
                  options={item?.options}
                  onChange={(event) => item?.handlechange(event)}
                  aria-label={`Select ${item?.display?.toLowerCase()}`}
                  placeholder=""
                  id={item?.display}
                  value={item?.selectedvalue}
                />
              ) : (
                <>
                  {/* <div className={item?.gridrow}> */}
                  <Form.Select
                    onChange={(event) => onChange(event, index)}
                    name={item?.name || ObjectUtils.getControlId(item?.texttoread)}
                    // className={item?.classname}
                    bsPrefix={item?.classname ? item?.classname : " "}
                    defaultValue={item?.selectedvalue}
                  >
                    {item?.options?.map((option: any, i: number) => (
                      <option key={option.value} value={item.options[i].value}>
                        {item?.showvalue ? option?.value : option?.text}
                      </option>
                    ))}
                  </Form.Select>
                  {/* </div> */}
                  {item?.errormessage && <AlertComponent marginleft={false} propessageType="error" propmessage={item.errormessage} />}
                  {item?.error && (
                    <Form.Control.Feedback type="invalid" role="alert" className={`ml-error ${item?.wrappererrorclass}`}>
                      {item?.error}
                    </Form.Control.Feedback>
                  )}
                  {item?.seperator && (
                    <span className={`input-sep align-items-center ${item?.isFocused ? "show" : "hide"}`} dangerouslySetInnerHTML={{ __html: item?.seperator }} />
                  )}
                </>
              ))}
          </Form.Group>
        )}
      </Fragment>
    );
  });
};

export default DropdownComponent;
